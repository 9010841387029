<script>
import moment from "moment";
// import Switches from "vue-switches";
import {
  required,
  // minLength
} from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
// import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
// import Multiselect from 'vue-multiselect';
import * as cloneDeep from "lodash.clonedeep";
import {
  SETUP_OR_GENERATE_2FA_OTP,
  UPDATE_SUB_USER_DETAILS,
  VERIFY_2FA_FOR_CURRENT_USER,
  FETCH_SUB_USER_DETAILS_BY_ID,
} from "@/state/actions.type";
import OtpInput from "@bachdgvn/vue-otp-input";
import Breadcrumb from '../../../components/breadcrumb.vue';

export default {
  components: {
    // Multiselect,
    // Switches,
    Layout,
    OtpInput,
    Breadcrumb
  },

  data() {
    return {
      output: null,
      twoFAUserDetails: null,
      googleAuthQRUrl: null,
      otpData: {
        otp: "",
      },
      submitted: false,
      verify2FAOtp: false,
    };
  },

  mounted() {
    this.twoFAUserDetails = cloneDeep(this.userDetails);
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          to: { name: 'Payouts' }
        },
        {
          text: 'Payout Settings',
          active: true,
        },
      ];
    },

    userDetails() {
      return this.$store.getters["auth/getCurrentUser"];
    },

  },

  watch: {
    userDetails() {
      this.twoFAUserDetails = cloneDeep(this.userDetails);
    },
  },

  validations: {
    otpData: {
      otp: { required },
    },
  },

  filters: {
    formateDate(value) {
      return moment(value).format("MMMM DD YYYY, h:mm:ss a");
    },
  },

  methods: {
    ...notificationMethods,

    resetOTPData() {
      this.otpData = {
        otp: ""
      };
    },

    // eslint-disable-next-line no-unused-vars
    verifyOTP(e) {
      this.submitted = true;
      console.log("this.$v", this.$v)
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        //
        console.log("otpData", this.otpData);
        const optDetails = {
          type: this.twoFAUserDetails.default_2fa_channel,
          otp: this.otpData.otp,
        };
        this.$store
          .dispatch(`accounts/${VERIFY_2FA_FOR_CURRENT_USER}`, optDetails)
          .then(responseData => {
            this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.twoFAUserDetails.subUserUuid, subUserDetails: this.twoFAUserDetails} )
            this.verify2FAOtp = false;
            console.log("Success of VERIFY_2FA_FOR_CURRENT_USER", responseData);
            this.resetOTPData();
          })
          .catch(error => {
            this.resetOTPData();
            console.log("Error while verifying OTP", error);
          })

      }
      this.submitted = false;
    },

    resetNotificationError() {
      this.$store.dispatch("notification/clear");
    },

    openOtpModel() {
      this.resetOTPData();
      this.resetNotificationError();
      this.verify2FAOtp = true;
    },

    open2FAModal(){
      console.log("open2FAModal", this.twoFAUserDetails)
      const setUp2FADetails = {
        type: this.twoFAUserDetails.default_2fa_channel,
        action:"setup"
      };
      if (this.twoFAUserDetails && this.twoFAUserDetails.default_2fa_channel === 'google-auth' && this.twoFAUserDetails.is_google_auth_setup) {
        // Don't call QR code directly open OTP
        this.openOtpModel();
      } else {
        this.$store
          .dispatch(`accounts/${SETUP_OR_GENERATE_2FA_OTP}`, setUp2FADetails)
          .then(responseData => {
            if(responseData.type === 'google-auth') {
              this.googleAuthQRUrl = responseData.QRCodeImage;
            }
            this.googleAuthQRUrl = responseData.QRCodeImage;
            this.openOtpModel();
            // Open OTP Input Modal
            console.log("Response of SETUP_OR_GENERATE_2FA_OTP", responseData);
          })
          .catch(error => {
            console.log("Error of SETUP_OR_GENERATE_2FA_OTP", error);
          })
        // this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, this.twoFAUserDetails)
        // this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.twoFAUserDetails.subUserUuid, subUserDetails: this.twoFAUserDetails} )
      }
    },

    toggleSwitchFor2FASetup(val) {
      if(this.twoFAUserDetails.default_2fa_channel !== "") {
        // this.twoFAUserDetails.is_2fa_enabled = val
        const twoFAUserDetailsCopy = cloneDeep(this.userDetails);
        twoFAUserDetailsCopy.is_2fa_enabled = val;
        console.log("Backend request -- Updating 2fa ", val)
        this.$store
          .dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: twoFAUserDetailsCopy.subUserUuid, subUserDetails: twoFAUserDetailsCopy} )
          .then(responseData => {
            this.$store.dispatch(`accounts/${FETCH_SUB_USER_DETAILS_BY_ID}`)
            // Open OTP Input Modal
            console.log("Response of SETUP_OR_GENERATE_2FA_OTP", responseData);
          })
          .catch(error => {
            console.log("Error of SETUP_OR_GENERATE_2FA_OTP", error);
          });
      } else {
        this.twoFAUserDetails.is_2fa_enabled = val
        console.log("No backend request -- Updating 2fa ", val)
      }
    },

    resendOTP() {
      const setUp2FADetails = {
        type: this.twoFAUserDetails.default_2fa_channel,
        action:"resend"
      };
      this.$store
        .dispatch(`accounts/${SETUP_OR_GENERATE_2FA_OTP}`, setUp2FADetails)
        .then(responseData => {
          console.log("Successfully resend OTP", responseData);
        })
        .catch(error => {
          console.log("Error while resending OTP", error);
        })
    },

    handleOtpChange(value) {
      this.otpData.otp = value;
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <breadcrumb :items="items" :goBackTo="{ name: 'Payouts' }" />

    <b-alert
      variant="danger"
      class="mt-3"
      v-if="notification.message"
      show
      dismissible
      @dismissed="resetNotificationError"
      >{{ notification.message }}</b-alert
    >
    <div class="row">
      <div class="col-lg-12">
        <div class="row" v-if="twoFAUserDetails">
          <div class="col-sm-12 col-md-12">
            <!-- <div class="row mt-2 ml-1">
              <div class="d-flex align-items-center" > -->
                <!-- <label class="mr-2" style="font-size: 1.2rem;" for="is_active">{{ $t("profile.2fa") }}</label> -->
                <!-- <switches
                  v-model="twoFAUserDetails.is_2fa_enabled"
                  type-bold="true"
                  color="primary"
                  class="ml-1 mt-3 font-size-10"
                ></switches> -->
              <!-- </div>
            </div> -->

            
            <div class="toggle-settings">
              <div class="d-flex" style="gap: 15px;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.4902 2.23006L5.50016 4.11006C4.35016 4.54006 3.41016 5.90006 3.41016 7.12006V14.5501C3.41016 15.7301 4.19016 17.2801 5.14016 17.9901L9.44016 21.2001C10.8502 22.2601 13.1702 22.2601 14.5802 21.2001L18.8802 17.9901C19.8302 17.2801 20.6102 15.7301 20.6102 14.5501V7.12006C20.6102 5.89006 19.6702 4.53006 18.5202 4.10006L13.5302 2.23006C12.6802 1.92006 11.3202 1.92006 10.4902 2.23006Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.05078 11.8702L10.6608 13.4802L14.9608 9.18018" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div style="padding-right: 90px;">
                    <p class="my-auto flex-fill">
                      Secure Your Account
                    </p>
                    <p style="margin-bottom: 0px;font-size: 12px;line-height: 20px;margin-top: 6px;color: rgba(0, 0, 0, 0.30);">
                      Two -factor authentication adds an extra layer of security to your account. By enabling this feature, you will receive a 6-digits code for every payout.
                    </p>
                  </div>
                  <div>
                    <!-- <switches
                      :value="twoFAUserDetails.is_2fa_enabled"
                      type-bold="true"
                      color="primary"
                      class="mb-0"
                      @input="toggleSwitchFor2FASetup($event)"
                    ></switches> -->
                    <div class="d-flex">
                      <b-btn
                        v-if="!twoFAUserDetails.is_2fa_enabled"
                        variant="primary" 
                        class="cancel bg-white text-dark"
                        @click="toggleSwitchFor2FASetup(true)"
                        style="border: 1.50px #DCDAD5 solid;padding: 12px 24px;"
                      >
                        Disable
                      </b-btn>
                        <button
                        v-if="twoFAUserDetails.is_2fa_enabled"
                        class="btn btn-primary"
                        @click="toggleSwitchFor2FASetup(false)"
                        style="padding: 12px 24px;"
                      >
                          Enable
                        </button>
                    </div>
                  </div>
              </div>
              <div style="border-top: 1px rgba(220, 218, 213, 0.30) solid; margin-top: 25px;"></div>
              <div style="margin-top: 25px;" v-if="twoFAUserDetails.is_2fa_enabled">
                <div class="row" >
                  <!-- <span style="width: 200px;">{{ $t("profile.default_2fa_channel") }}</span>
                  <multiselect
                    style="width: 250px;"
                    v-model="twoFAUserDetails.default_2fa_channel"
                    :options="['email', 'phone', 'google-auth']"
                    class="sm-multiselect"
                    :allow-empty="false"
                    :searchable="false"
                    :multiple="false"
                    :taggable="false"
                  ></multiselect> -->
                  
                  <div class="col-md-4 col-sm-6 col-6 paymentContent customRadioFor2fa">
                      <input style="display: none;" type="radio" v-model="twoFAUserDetails.default_2fa_channel" value="google-auth" v-bind:id="`google-auth`" name="paymentOption">
                      <div class="paymentLabel">
                          <label style="margin-bottom: 0px; cursor: pointer;" v-bind:for="`google-auth`" > 
                            <span style="display: inline-block;">
                              <p style="margin-bottom: 0px;font-size: 14px;line-height: 20px;color: #000;">Google Authentication</p> 
                              <p style="margin-bottom: 0px;color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 20px;font-weight: 325;">Authentication by Google</p>
                            </span>
                          </label>
                      </div>
                  </div>

                  <div class="col-md-4 col-sm-6 col-6 paymentContent customRadioFor2fa">
                      <input style="display: none;" type="radio" v-model="twoFAUserDetails.default_2fa_channel" value="email" v-bind:id="`email`" name="paymentOption">
                      <div class="paymentLabel">
                          <label style="margin-bottom: 0px; cursor: pointer;" v-bind:for="`email`" > 
                            <span style="display: inline-block;">
                              <p style="margin-bottom: 0px;font-size: 14px;line-height: 20px;color: #000;">Email</p> 
                              <p style="margin-bottom: 0px;color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 20px;font-weight: 325;">Authentication by Email</p> 
                            </span>
                          </label>
                      </div>
                  </div>

                <div class="col-md-3 col-sm-6 col-6 paymentContent customRadioFor2fa">
                      <input style="display: none;" type="radio" v-model="twoFAUserDetails.default_2fa_channel" value="phone" v-bind:id="`phone`" name="paymentOption">
                      <div class="paymentLabel">
                          <label style="margin-bottom: 0px; cursor: pointer;" v-bind:for="`phone`" > 
                            <span style="display: inline-block;">
                              <p style="margin-bottom: 0px;font-size: 14px;line-height: 20px;color: #000;">Phone</p> 
                              <p style="margin-bottom: 0px;color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 20px;font-weight: 325;">Authentication by Phone</p>
                            </span>
                          </label>
                      </div>
                </div>
                <div class="col-md-1 col-sm-6 col-6">
                  <button class="btn btn-primary btn-sm text-nowrap float-right" @click="open2FAModal()">{{ $t("button.update") }}</button>
                </div>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="md"
      id="verify2FAOtpModal"
      v-model="verify2FAOtp"
      title=""
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
      header-class="twoFA-modal-header-custom"
      body-class="twoFA-modal-body-custom"
    >
      <b-alert
        variant="danger"
        class="mt-3"
        v-if="notification.message"
        show
        dismissible
        @dismissed="resetNotificationError"
      > {{ notification.message }}</b-alert>
      
      <div>
        <div class="mb-2">
          <!-- <span >{{ $t('profile.2fa_msg') }}</span>
          <span v-else>{{ $t('profile.2fa_otp_msg') }}</span> -->
          <div v-if="twoFAUserDetails && twoFAUserDetails.default_2fa_channel == 'google-auth'">
              <div style="line-height: 20px;margin-bottom: 4px;font-size: 18px;">Two Step Verification</div>
              <div style="font-size: 12px;font-weight: 325;line-height: 20px;color: rgba(0, 0, 0, 0.30);margin-bottom: 16px;">Please follow the instructions below</div>
              <div style="background: rgba(220, 218, 213, 0.30);border-radius: 12px;padding: 16px;">
                <div style="display: flex;align-items: center;"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                  <rect x="0.5" y="0.5" width="13" height="17" rx="3.5" stroke="#FC6B2D"/>
                  </svg>
                  <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;padding-left: 12px;">Install Google Authenticator </div>
                </div>
                <div style="display: flex;align-items: center; margin-top: 12px;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g id="vuesax/linear/scan-barcode">
                  <g id="scan-barcode">
                  <path id="Vector" d="M1.5 6.75V4.875C1.5 3.0075 3.0075 1.5 4.875 1.5H6.75" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M11.25 1.5H13.125C14.9925 1.5 16.5 3.0075 16.5 4.875V6.75" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M16.5 12V13.125C16.5 14.9925 14.9925 16.5 13.125 16.5H12" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_4" d="M6.75 16.5H4.875C3.0075 16.5 1.5 14.9925 1.5 13.125V11.25" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_5" d="M7.875 5.25V6.75C7.875 7.5 7.5 7.875 6.75 7.875H5.25C4.5 7.875 4.125 7.5 4.125 6.75V5.25C4.125 4.5 4.5 4.125 5.25 4.125H6.75C7.5 4.125 7.875 4.5 7.875 5.25Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_6" d="M13.875 5.25V6.75C13.875 7.5 13.5 7.875 12.75 7.875H11.25C10.5 7.875 10.125 7.5 10.125 6.75V5.25C10.125 4.5 10.5 4.125 11.25 4.125H12.75C13.5 4.125 13.875 4.5 13.875 5.25Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_7" d="M7.875 11.25V12.75C7.875 13.5 7.5 13.875 6.75 13.875H5.25C4.5 13.875 4.125 13.5 4.125 12.75V11.25C4.125 10.5 4.5 10.125 5.25 10.125H6.75C7.5 10.125 7.875 10.5 7.875 11.25Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_8" d="M13.875 11.25V12.75C13.875 13.5 13.5 13.875 12.75 13.875H11.25C10.5 13.875 10.125 13.5 10.125 12.75V11.25C10.125 10.5 10.5 10.125 11.25 10.125H12.75C13.5 10.125 13.875 10.5 13.875 11.25Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  </g>
                  </svg>
                  <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;padding-left: 12px;">Scan QR Code with an authenticator app to generate OTP </div>
                </div>
              </div>
              <div v-if="googleAuthQRUrl" style="margin-top: 24px;" class="text-center">
                <img :src="`${googleAuthQRUrl}`" alt style="width: 120px; object-fit: contain;"/>
              </div>
              <div style="margin-top: 32px;line-height: 20px;font-size: 12px;text-align: center;">Type your 6-digit security code</div>
          </div>

          <div class="text-center" v-if="twoFAUserDetails && twoFAUserDetails.default_2fa_channel == 'phone'">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="45" viewBox="0 0 26 45" fill="none">
              <g id="Union">
              <mask id="path-1-inside-1_2_108045" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z"/>
              </mask>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z" fill="#DCDAD5" fill-opacity="0.3"/>
              <path d="M23.2143 6.42857V8.92857H25.7143V6.42857H23.2143ZM2.5 6.42857H0V8.92857H2.5V6.42857ZM23.2143 38.5714H25.7143V36.0714H23.2143V38.5714ZM2.5 38.5714V36.0714H0V38.5714H2.5ZM19.7143 0H6V5H19.7143V0ZM25.7143 6C25.7143 2.68629 23.028 0 19.7143 0V5C20.2666 5 20.7143 5.44771 20.7143 6H25.7143ZM25.7143 6.42857V6H20.7143V6.42857H25.7143ZM2.5 8.92857H23.2143V3.92857H2.5V8.92857ZM0 6V6.42857H5V6H0ZM6 0C2.68629 0 0 2.68629 0 6H5C5 5.44772 5.44771 5 6 5V0ZM2.5 38.5714V6.42857H-2.5V38.5714H2.5ZM-2.5 6V6.42857H2.5V6H-2.5ZM6 -2.5C1.30558 -2.5 -2.5 1.30558 -2.5 6H2.5C2.5 4.067 4.067 2.5 6 2.5V-2.5ZM19.7143 -2.5H6V2.5H19.7143V-2.5ZM28.2143 6C28.2143 1.30558 24.4087 -2.5 19.7143 -2.5V2.5C21.6473 2.5 23.2143 4.067 23.2143 6H28.2143ZM28.2143 6.42857V6H23.2143V6.42857H28.2143ZM23.2143 6.42857V38.5714H28.2143V6.42857H23.2143ZM28.2143 39V38.5714H23.2143V39H28.2143ZM19.7143 47.5C24.4087 47.5 28.2143 43.6944 28.2143 39H23.2143C23.2143 40.933 21.6473 42.5 19.7143 42.5V47.5ZM6 47.5H19.7143V42.5H6V47.5ZM-2.5 39C-2.5 43.6944 1.30558 47.5 6 47.5V42.5C4.067 42.5 2.5 40.933 2.5 39H-2.5ZM-2.5 38.5714V39H2.5V38.5714H-2.5ZM25.7143 39V38.5714H20.7143V39H25.7143ZM19.7143 45C23.028 45 25.7143 42.3137 25.7143 39H20.7143C20.7143 39.5523 20.2666 40 19.7143 40V45ZM6 45H19.7143V40H6V45ZM0 39C0 42.3137 2.68629 45 6 45V40C5.44772 40 5 39.5523 5 39H0ZM0 38.5714V39H5V38.5714H0ZM23.2143 36.0714H2.5V41.0714H23.2143V36.0714Z" fill="black" mask="url(#path-1-inside-1_2_108045)"/>
              </g>
              </svg>
              <div style="margin-top: 24px;line-height: 20px;margin-bottom: 12px;">Two Step Verification</div>
              <div style="color: rgba(0, 0, 0, 0.30);font-size: 12px;margin-bottom: 25px;">Enter the verification code we sent to your mobile number</div>
              <div>{{twoFAUserDetails.phone_number}}</div>
              <div style="margin-top: 32px;line-height: 20px;font-size: 12px;">Type your 6-digit security code</div>
          </div>
          <div class="text-center" v-if="twoFAUserDetails && twoFAUserDetails.default_2fa_channel == 'email'">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
              <path d="M31.875 38.4375H13.125C7.5 38.4375 3.75 35.625 3.75 29.0625V15.9375C3.75 9.375 7.5 6.5625 13.125 6.5625H31.875C37.5 6.5625 41.25 9.375 41.25 15.9375V29.0625C41.25 35.625 37.5 38.4375 31.875 38.4375Z" stroke="black" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M31.875 16.875L26.0062 21.5625C24.075 23.1 20.9062 23.1 18.975 21.5625L13.125 16.875" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div style="margin-top: 24px;line-height: 20px;margin-bottom: 12px;">Two Step Verification</div>
              <div style="color: rgba(0, 0, 0, 0.30);font-size: 12px;margin-bottom: 25px;">Enter the verification code we sent to your email address</div>
              <div>{{twoFAUserDetails.email_address}}</div>
              <div style="margin-top: 32px;line-height: 20px;font-size: 12px;">Type your 6-digit security code</div>
          </div>



        </div>
        <form class="text-center" @submit.prevent="verifyOTP">

          <div class="form-group">
            <!-- <label for="otpVal">{{ $t('profile.otp') }}<span class="ml-1 text-danger">*</span></label> -->
            <!-- <input
              id="otpVal"
              type="text"
              class="form-control"
              placeholder="Enter One Time Password"
              v-model="otpData.otp"
              :class="{ 'is-invalid': submitted && $v.otpData.otp.$error }"
            /> -->
            <OtpInput
              id="otpVal"
              :input-classes="submitted && $v.otpData.otp.$error ? 'is-invalid otp-input form-control' : 'otp-input form-control'"
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOtpChange"
            />
            <div
              v-if="submitted && !$v.otpData.otp.required"
              class="invalid-feedback"
            > {{ $t('profile.otp_required') }} </div>
          </div>
          <div style="margin-top=25px;">
            <button style="margin: 0 auto;line-height: 20px;padding: 12px 140px; display: block;" type="submit" class="btn btn-primary">{{ $t('button.submit') }}</button>
            <span style="margin-top: 16px;display: inline-block;font-size: 12px;margin-bottom: 40px;"> Didn’t get the code? <span class="text-chai" style="cursor: pointer;" @click="resendOTP">{{ $t('profile.resend_otp') }}</span></span>
          </div>
            <!-- <b-button class="mr-1" variant="light" @click="verify2FAOtp = false">{{ $t('button.close') }}</b-button> -->
        </form>
      </div>

    </b-modal>
  </Layout>
</template>

<style lang="scss" scoped>

.font-size-num {
  font-size: 14px;
  margin-top: 4.5px;
  font-weight: bold !important;
}

.font-size {
  font-size: 16px;
  font-weight: bold !important;
}

.dropdown-container select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}


.dropdown-container select::-ms-expand {
  display: none;
}
.toggle-settings {
    margin-top: 25px;
    padding: 25px;
    border-radius: 8px;
    border: 1px solid #DCDAD5;
}

/* radio button  */
.customRadioFor2fa > input[type="radio"] {
    opacity: 0;

    &:hover{
        cursor: pointer;
    }
}
.customRadioFor2fa > input[type="radio"] + * label::before {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1.5px;
    border-color:#DCDAD5;
    cursor: pointer;
    margin-left: 12px;
    
}
.customRadioFor2fa > input[type="radio"]:checked + * {
    color: #fc6b2d;
}
.customRadioFor2fa > input[type="radio"]:checked + * label::before {
    background: radial-gradient(#fc6b2d 0%, #fc6b2d 40%, transparent 50%, transparent);
}
.customRadioFor2fa > input[type="radio"] + * {
    margin-left: -10px;
    margin-top: 0px;    
}
::v-deep .otp-input.form-control{
  width: 38px;
  height: 40px;
  border-radius: 12px;
  margin-left: 10px;
}
::v-deep #otpVal{
      justify-content: center;
}
</style>
<style >
  .twoFA-modal-header-custom{
      border-bottom: none;
      padding-bottom: 0px;
  }
  .twoFA-modal-body-custom{
      padding: 16px 40px 16px 40px;
  }
</style>